
import { Component, Vue } from "vue-property-decorator";
import lsPagination from "@/components/ls-pagination.vue";
import ExportData from "@/components/export-data/index.vue";
import LsDialog from "@/components/ls-dialog.vue";
import { RequestPaging } from "@/utils/util";
import { apiModelList, apiModelDetail } from "@/api/model.ts";
@Component({
  components: {
    lsPagination,
    ExportData,
    LsDialog,
  },
})
export default class ModelList extends Vue {
  $refs!: { table: any };
  /** S Data **/

  pager: RequestPaging = new RequestPaging();
  dialogPager: RequestPaging = new RequestPaging();

  searchObj: any = {
    id: "",
    title: "",
    cate_id: "",
  };
  categoryList = [];
  apiModelList = apiModelList;
  //1=内测,2=禁用,3=启用
  statusList = [
    { label: "内测", value: 1 },
    { label: "禁用", value: 2 },
    { label: "启用", value: 3 },
  ];
  /** E Data **/
  getList() {
    this.pager.request({
      callback: apiModelList,
      params: {
        ...this.searchObj,
      },
    });
  }

  // 添加
  handleAdd() {
    this.$router.push({ path: "/model/model_edit" });
  }

  handleEdit(id: string) {
    this.$router.push({
      path: "/model/model_edit",
      query: { id: id, mode: "edit" },
    });
  }

  // 重置搜索
  reset(): void {}

  // 编辑
  toModelEdit(id: number | any) {}

  // 删除
  handleDelete(id: number) {}
  /** E Methods **/

  /** S ods **/
  created() {
    this.getList();
  }
}
